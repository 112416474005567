<template>
  <div class="tabs-num-depot-manquant">
    <b-table
      responsive
      show-empty
      id="my-table"
      class="custom-table-style custom-table-rapport-style custom-table-doublent"
      :items="getDossierEronneTh"
      :fields="fields"
      sticky-header
      bordered
      hover
      head-variant="light"
      empty-text="Il n'y a aucun enregistrement à afficher"
    >
      <template v-slot:cell(numero_dossier)="data">
        {{ data.item.numero_dossier }}
      </template>
      <template v-slot:cell(error)="data">
        {{ data.item.error }}
      </template>
      <template v-slot:cell(depot)="data">
        <font-awesome-icon
          icon="trash"
          class="icon-trash-component"
          @click.prevent="deleteProject(data.item)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      fields: [
        {
          key: 'numero_dossier',
          label: 'Numéro dossier'
        },

        {
          key: 'error',
          label: 'Erreur'
        },
        {
          key: 'depot',
          label: ''
        }
      ]
    };
  },
  methods: {
    ...mapActions(['fetchDossierEronneTh', 'deleteErroeProjetTh']),
    deleteProject(payload) {
      this.deleteErroeProjetTh(payload);
    }
  },
  computed: {
    ...mapGetters(['getDossierEronneTh', 'getAllDepotForYearTh']),
    computedGetALLDepotForYearTh() {
      return this.getAllDepotForYearTh.map(item => ({
        full_name: item.depot,
        ...item
      }));
    }
  },
  mounted() {
    this.fetchDossierEronneTh();
  }
};
</script>

<style lang="scss">
.tabs-num-depot-manquant {
  height: 100%;
}
.custom-table-doublent {
  max-height: calc(100vh - 197px);
  height: calc(100vh - 197px);
}
</style>
